@import url('https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap');
body {
    background-color: white;
    font-family: 'Roboto Mono', monospace;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
}

ul {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

li {
    max-width: 200px;
    max-height: 100px;
    border: 1px solid black;
    flex: auto;
    margin: 10px;
    word-break: break-all;
}

.add-btn {
    border: none;
    background: none;
    font-size: 1.5rem;
}

.add-btn:hover {
    transform: scale(1.5);
}

.todo-input {
    margin: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    font-family: inherit;
    outline: 0;
    box-sizing: border-box;
    width: 100%;
    display: block;
    border: 1px solid #bdc3c7;
    border-radius: 5px;
    padding: .75em;
    font-size: 1em;
    line-height: 1em;
    height: 2.5em;
}

.todo-input:focus {
    border: 1px solid #f7d7c4;
}

.date-and-time {
    vertical-align: middle;
    display: block;
    height: 100%;
    font-size: 10px;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-lg {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem;
}

.btn {
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    border-radius: .25rem;
}

.btn:hover {
    cursor: pointer;
}